$('a[href*=#]').on('click', function(event){
    if (window.location.href.split("#")[0] == this.href.split("#")[0]) {
        event.preventDefault();
        var hash = this.hash;
        hash = hash.substring(1);
        var target = $('a[name='+hash+']');
        console.log(hash);
        if (target.length > 0) {
            $('html,body').animate({scrollTop:target.offset().top}, 500);
        }
    }
});


$('#battle-search').on('input', function(e) {
    var search = $(this).val();
    if (search == '') {
        $('#battle-dropdown .search-result').remove();
        $('#battle-dropdown .all-battles').removeClass('hidden');
    }

    $.ajax(
    {
        url: "/api/battle",
        type: "POST",
        data: {
            'search': search,
        },
        headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: "json",
        success: function(response) {
            $('#battle-dropdown .search-result').remove();
            $('#battle-dropdown .all-battles').addClass('hidden');
            if (response.length > 0) {
                $.each(response, function (index, result) {
                    $('#battle-dropdown').append('<li class="search-result"><a href="'+result.url+'">'+result.name+'</a></li>');
                });
            }
            else {
                $('#monster-dropdown').append('<li class="search-result"><a>No Battles Found</a></li>');
            }
        }
    });
});

$('#monster-search').on('input', function(e) {
    var search = $(this).val();
    if (search == '') {
        $('#monster-dropdown .search-result').remove();
        $('#monster-dropdown .all-monsters').removeClass('hidden');
    }

    $.ajax(
    {
        url: "/api/monster",
        type: "POST",
        data: {
            'search': search,
        },
        headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: "json",
        success: function(response) {
            $('#monster-dropdown .search-result').remove();
            $('#monster-dropdown .all-monsters').addClass('hidden');
            if (response.length > 0) {
                $.each(response, function (index, result) {
                    $('#monster-dropdown').append('<li class="search-result"><a href="'+result.url+'">'+result.name+'</a></li>');
                });
            }
            else {
                $('#monster-dropdown').append('<li class="search-result"><a>No Monsters Found</a></li>');
            }
        }
    });
});

$('#boss-search').on('input', function(e) {
    var search = $(this).val();
    if (search == '') {
        $('#boss-dropdown .search-result').remove();
        $('#boss-dropdown .all-bosses').removeClass('hidden');
    }

    $.ajax(
    {
        url: "/api/boss",
        type: "POST",
        data: {
            'search': search,
        },
        headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: "json",
        success: function(response) {
            $('#boss-dropdown .search-result').remove();
            $('#boss-dropdown .all-bosses').addClass('hidden');
            if (response.length > 0) {
                $.each(response, function (index, result) {
                    $('#boss-dropdown').append('<li class="search-result"><a href="'+result.url+'">'+result.name+'</a></li>');
                });
            }
            else {
                $('#boss-dropdown').append('<li class="search-result"><a>No Bosses Found</a></li>');
            }
        }
    });
});